import { createSlice } from "@reduxjs/toolkit";

export const programSlice = createSlice({
	name: "program",
	initialState: {
		name: "",
	},

	reducers: {
		changeProgram: (state, action) => {
			state.name = action.payload;
		},
	},
});

export const { changeProgram } = programSlice.actions;
export default programSlice.reducer;

import { useNavigate } from "react-router-dom";

export default function Home() {
	const navigate = useNavigate();

	return (
		<div className="centered-container row">
			<button className="program" onClick={() => navigate("/triple-a")}>
				Triple A
			</button>
			<button className="program" onClick={() => navigate("/ivrs")}>
				IVRs
			</button>
		</div>
	);
}

import Cookies from "universal-cookie";
import { useState, useMemo } from "react";
import "../styles/esi-call-queue-count-button.css";

//Viv's Testing
import * as React from "react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#000000",
        },
        secondary: {
            main: "#1f9eef",
        },
    },
});

export const EsiCallQueueCountButton = ({ index, props }) => {
    const cookies = useMemo(() => new Cookies(), []);
    const [callsInQueue, setCallsInQueue] = useState("");
    const [callsLoading, setCallsLoading] = useState(false);

    const queueIndices = [1, 2];

    const getCallsInQueue = async (index) => {
        if (callsLoading[index]) return;

        try {
            // setCallsLoading(true);
            setCallsLoading((prev) => ({ ...prev, [index]: true }));

            const callQueueCountResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/esi-aaa-ivr-get-calls-in-queue?queue=${index}`, {
                method: "GET",
                redirect: "follow",
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
            });
            const callQueueCountData = await callQueueCountResponse.json();
            console.log(callQueueCountData);

            //setCallsInQueue(callQueueCountData.message);
            setCallsInQueue((prev) => ({ ...prev, [index]: callQueueCountData.message }));
            setCallsLoading(false);
        } catch (err) {
            console.log(err);
        } finally {
            setCallsLoading((prev) => ({ ...prev, [index]: false }));
        }
    };

    return (
        // <ThemeProvider theme={darkTheme}>
        //     <Button
        //         variant="contained"
        //         onClick={getCallsInQueue}
        //         value={index}
        //         color="primary"
        //         sx={{
        //             padding: "16px 32px",
        //             height: "auto",
        //             width: "auto",
        //         }}
        //     >
        //         {callsInQueue[index] !== "" ? (
        //             <div className="label">
        //                 <Typography variant="h4">Calls in Queue {index}</Typography>
        //                 <p className="value">
        //                     <Typography variant="h3">{callsInQueue}</Typography>{" "}
        //                 </p>

        //                 <div>{callsLoading[index] ? <span className="load"></span> : <p></p>}</div>
        //             </div>
        //         ) : (
        //             <div className="label">
        //                 <Typography variant="h4">Check Queue {index}</Typography>
        //                 <div>{callsLoading[index] ? <span className="load"></span> : <p></p>}</div>
        //             </div>
        //         )}
        //     </Button>
        // </ThemeProvider>

        //Current Call Count Button
        <ThemeProvider theme={darkTheme}>
            {queueIndices.map((index) => (
                <Box key={index} my={2}>
                    <Button
                        variant="contained"
                        onClick={() => getCallsInQueue(index)}
                        color="primary"
                        sx={{
                            padding: "16px 32px",
                            height: "auto",
                            width: "auto",
                        }}
                    >
                        {callsInQueue[index] !== undefined ? (
                            <div className="label">
                                <Typography variant="h4">Calls in Queue {index}</Typography>
                                <p className="value">
                                    <Typography variant="h3">{callsInQueue[index]}</Typography>
                                </p>
                                <div>{callsLoading[index] ? <span className="load"></span> : <p></p>}</div>
                            </div>
                        ) : (
                            <div className="label">
                                <Typography variant="h4">Check Queue {index}</Typography>
                                <div>{callsLoading[index] ? <span className="load"></span> : <p></p>}</div>
                            </div>
                        )}
                    </Button>
                </Box>
            ))}
        </ThemeProvider>

        //Older Component
        // <button type="button" className="widget" onClick={getCallsInQueue}>
        //     {callsInQueue !== "" ? (
        //         <div className="label">
        //             Calls in Queue
        //             <p className="value">{callsInQueue} </p>
        //             <div>{callsLoading ? <span className="load"></span> : <p></p>}</div>
        //         </div>
        //     ) : (
        //         <div className="label">
        //             Check Calls in Queue
        //             <div>{callsLoading ? <span className="load"></span> : <p></p>}</div>
        //         </div>
        //     )}

        //     <svg viewBox="-5 -5 110 110" preserveAspectRatio="none" aria-hidden="true">
        //         <path d="M0,0 C0,0 100,0 100,0 C100,0 100,100 100,100 C100,100 0,100 0,100 C0,100 0,0 0,0" />
        //     </svg>
        // </button>
    );
};

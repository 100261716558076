import * as React from "react";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

export default function FileUploadButton({ ...buttonProps }) {
    const [file, setFile] = React.useState(null);
    const inputFileRef = React.useRef();
    const [uploadLoading, setUploadLoading] = React.useState(false);
    // const [confirmationModalActive, setConfirmationModalActive] = useState(false);
    // cant use fs in react application, (which makes sense unsafe)
    // const processSheet = async (e) => {
    //   const fileStream = await xlstream.getXlsxStream({
    //     filePath: file,
    //     sheet: "dynamically get file path",
    //     withHeader: true,
    //   });
    // };

    return (
        <Button
            component="label"
            startIcon={<FileUploadOutlinedIcon color="secondary" sx={{ width: 24, height: 24 }} />}
            // onClick={upload}
            // onChange={fileState()}
            // onChange={(e) => {
            //   setFile(() => e.target.files[0]);
            //   setFileText(e.target.files[0].name);
            // }}
            {...buttonProps}
            accept=".csv"
            sx={{ marginTop: 1, marginRight: 0, color: "white" }}
        >
            {buttonProps.filetext.length > 0 ? `${buttonProps.filetext.slice(0, 10)} ...` : ""}
            <VisuallyHiddenInput type="file" accept={buttonProps.accept} ref={setFile} />
        </Button>
    );
}

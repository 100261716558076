import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App.js";
import store from "./redux/store.js";
import reportWebVitals from "./reportWebVitals.js";
import { AuthProvider } from "react-auth-kit";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <AuthProvider authType="cookie" authName="_auth" cookieDomain={window.location.hostname} cookieSecure={true}>
                <App />
            </AuthProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import Switch from "@mui/material/Switch";
import EsiAaaIvr from "../components/EsiAaaIvr.jsx";
import OptumAaaIvr from "../components/OptumAaaIvr.jsx";
import CaremarkBpsIvr from "../components/CaremarkBpsIvr.jsx";
import CvsAaaIvr from "../components/CvsAaaIvr.jsx";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DropdownMenu } from "../components/DropdownMenu.jsx";
import { changeIvrProcessor } from "../redux/ivrProcessor.js";
import { changeProgram } from "../redux/program.js";
import { changeBatch } from "../redux/batch.js";
import { useDispatch } from "react-redux";
import resultLogStyles from "../styles/result-log.module.css";
import dropdownMenuStyles from "../styles/dropdown-menu.module.css";

export default function IVRs() {
    const dispatch = useDispatch();
    const ivrProcessorName = useSelector((state) => state.ivrProcessor.name);
    const programName = useSelector((state) => state.program.name);
    // const [batchProcessingEnabled, setBatchProcessingEnabled] = useState(false);
    const batchProcessingEnabled = useSelector((state) => state.batch.name);

    useEffect(() => {
        dispatch(changeProgram("Select program"));
    }, [dispatch]);

    return (
        <div className="centered-container">
            <div className={dropdownMenuStyles.dropdownMenuContainer}>
                {batchProcessingEnabled && (ivrProcessorName === "ESI" || ivrProcessorName === "Optum" || ivrProcessorName === "CVS") ? (
                    <DropdownMenu
                        defaultMessage={"Select program"}
                        menuOptions={["Mayne", "Myfembree", "Optinose", "Ubrelvy", "Zoryve", "Lilly", "Nurtec", "Paxlovid", "Auvelity"]}
                        reduxState={programName}
                        reduxStateChangeFunc={changeProgram}
                    ></DropdownMenu>
                ) : (
                    <></>
                )}
                <DropdownMenu
                    defaultMessage={"Select IVR"}
                    menuOptions={["ESI", "Caremark Accumulator", "Optum"]}
                    reduxState={ivrProcessorName}
                    reduxStateChangeFunc={changeIvrProcessor}
                ></DropdownMenu>
            </div>
            {ivrProcessorName === "ESI" || ivrProcessorName === "Optum" || ivrProcessorName === "CVS" ? (
                <div className={resultLogStyles.batchProcessingToggle}>
                    <Switch checked={batchProcessingEnabled} onClick={() => dispatch(changeBatch())} />
                    <p className={resultLogStyles.batchProcessingToggleText}>Batch Processing</p>
                </div>
            ) : (
                <></>
            )}
            <div className="program">
                <h1>{ivrProcessorName ? `${ivrProcessorName.toUpperCase()} IVR` : "IVRs"}</h1>
                {(() => {
                    if (ivrProcessorName === "ESI") {
                        return <EsiAaaIvr batchProcessingEnabled={batchProcessingEnabled}></EsiAaaIvr>;
                    } else if (ivrProcessorName === "Caremark Accumulator") {
                        return <CaremarkBpsIvr></CaremarkBpsIvr>;
                    } else if (ivrProcessorName === "Optum") {
                        return <OptumAaaIvr batchProcessingEnabled={batchProcessingEnabled}></OptumAaaIvr>;
                    } else if (ivrProcessorName === "CVS") {
                        return <CvsAaaIvr batchProcessingEnabled={batchProcessingEnabled}></CvsAaaIvr>;
                    } else {
                        return <></>;
                    }
                })()}
            </div>
        </div>
    );
}

export const isValidEsiIvrForm = (body) => {
    if (!body.npi || body.npi.length < 10) return "NPI should be 10 digits";
    else if (!body.rxNumber || body.rxNumber.length === 0) return "Please enter a rx number";
    else if (!body.dateFilled || !Object.prototype.toString.call(body.dateFilled) === "[object Date]") return "Please select a date filled";

    return;
};

export const isValidOptumIvrForm = (body) => {
    if (!body.npi || body.npi.length < 10) return "NPI should be 10 digits";
    else if (!body.rxNumber || body.rxNumber.length === 0) return "Please enter a rx number";

    return;
};

export const isValidCvsIvrForm = (body) => {
    if (!body.npi || body.npi.length < 10) return "NPI should be 10 digits";
    else if (!body.rxNumber || body.rxNumber.length === 0) return "Please enter a rx number";

    return;
};

export const isValidCaremarkBpsIvrForm = (body) => {
    if (!body.id) return "Please enter an ID";
    else if (!body.dob || !Object.prototype.toString.call(body.dob) === "[object Date]") return "Please select a date of birth";
    else if (!body.group) return "Please enter a group number";

    return;
};

export const isValidS3Upload = (file, programName) => {
    if (!file || !file.name) return "Please select a file";
    else if (!programName) return "Please select a program";

    return;
};

export const isValidAaaRefreshFile = (file, programName) => {
    const s3UploadErrorCheck = isValidS3Upload(file, programName);
    if (s3UploadErrorCheck) return s3UploadErrorCheck;
    else if (!file.name.toLowerCase().includes(programName.toLowerCase())) {
        return "File name must include the name of the program, please ensure the correct program is selected";
    }

    return;
};

export const isValidAaaHitlistRequest = (body) => {
    if (!body.aaaProcess) return "Please select a process mode";
    else if (!body.program) return "Please select a program";
    else if (!body.params.dateRangeStart || !Object.prototype.toString.call(body.params.dateRangeStart) === "[object Date]")
        return "Please select a start date";
    else if (!body.params.dateRangeEnd || !Object.prototype.toString.call(body.params.dateRangeEnd) === "[object Date]") return "Please select an end date";
    return;
};

export const isValidAaaSimpleViewRequest = (body) => {
    if (!body.aaaProcess) return "Please select a process mode";
    else if (!body.program) return "Please select a program";
    else if (!body.params.dateRangeStart || !Object.prototype.toString.call(body.params.dateRangeStart) === "[object Date]")
        return "Please select a start date";
    else if (!body.params.dateRangeEnd || !Object.prototype.toString.call(body.params.dateRangeEnd) === "[object Date]") return "Please select an end date";
    return;
};

export const isValidCallId = (body) => {
    if (!body.callId) return "Please enter a call id";
    else if (body.callId.slice(0, 2) !== "CA") return "Incorrect call id input, call ids should start with CA";

    return;
};

import DatePicker from "react-date-picker";
import { useState } from "react";
import { LoadingModal } from "./LoadingModal.jsx";
import { ConfirmationModal } from "./ConfirmationModal.jsx";
import { isValidCaremarkBpsIvrForm } from "../utils/scripts/inputValidations.js";
import { PTPException } from "../utils/models/ptpException.js";
import simpleFormStyles from "../styles/simple-form.module.css";
import "react-calendar/dist/Calendar.css";
import "../styles/react-date-picker.css";

export default function CaremarkBpsIvrIndividual({ cookies, updateCallLog }) {
	const [id, setId] = useState("");
	const [dob, setDob] = useState(new Date());
	const [group, setGroup] = useState("");
	const [loading, setLoading] = useState(false);
	const [confirmationModalActive, setConfirmationModalActive] = useState(false);

	const makeCaremarkBpsCall = async () => {
		try {
			setLoading(true);
			const body = { id: id, dob: dob, group: group };

			const formCheckError = isValidCaremarkBpsIvrForm(body);
			if (formCheckError) throw new PTPException(formCheckError);

			const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/caremark-bps-ivr`, {
				method: "POST",
				body: JSON.stringify(body),
				headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
			});

			if (!response.ok) {
				console.log(`Failure with call ${JSON.stringify(body)}`);
				throw new PTPException("Call could not be processed at this time");
			}

			await updateCallLog();

			displayConfirmationModal();
		} catch (err) {
			console.log(err);
			alert(err.name === "PTPException" ? err.message : "Call could not be processed at this time");
		} finally {
			setLoading(false);
		}
	};

	const displayConfirmationModal = () => {
		setConfirmationModalActive(() => true);

		setTimeout(() => {
			setConfirmationModalActive(() => false);
		}, 2500);
	};

	return (
		<div className="centered-container">
			{loading === true && <LoadingModal text="Processing call..." />}
			{confirmationModalActive && <ConfirmationModal text="Call successfully processed"></ConfirmationModal>}
			<form className={simpleFormStyles.simpleForm}>
				<div className={simpleFormStyles.simpleFormInputContainer}>
					<label className={simpleFormStyles.simpleFormLabel}>ID:</label>
					<input
						type="text"
						className={simpleFormStyles.simpleFormInput}
						onChange={(e) => {
							e.target.value = e.target.value.replace(/[^0-9a-zA-Z]+/, "");
							setId(() => e.target.value);
						}}
					></input>
				</div>
				<div className={simpleFormStyles.simpleFormInputContainer}>
					<label className={simpleFormStyles.simpleFormLabel}>Group Number: </label>
					<input
						type="text"
						className={simpleFormStyles.simpleFormInput}
						onChange={(e) => {
							e.target.value = e.target.value.replace(/[^0-9a-zA-Z]+/, "");
							setGroup(() => e.target.value);
						}}
					></input>
				</div>
				<div className={simpleFormStyles.simpleFormInputContainer}>
					<label className={simpleFormStyles.simpleFormLabel}>Date of Birth:</label>
					<DatePicker onChange={(newDate) => setDob(() => newDate)} value={dob} minDate={new Date("1900-01-01")} />
				</div>
				<div className={simpleFormStyles.simpleFormSubmitContainer}>
					<button type="button" className="run" onClick={makeCaremarkBpsCall}>
						Make Call
					</button>
				</div>
			</form>
		</div>
	);
}

import resultLogStyles from "../styles/result-log.module.css";
import Cookies from "universal-cookie";
import { useState, useMemo, useCallback, useRef, useEffect } from "react";

//Viv's Testing MUI Imports
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { AppBar, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import Button from "@mui/material/Button";

//Table Imports Testing
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

//Import Tests
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";

//Date Picker
import DatePicker from "react-date-picker";
import simpleFormStyles from "../styles/simple-form.module.css";
import "react-calendar/dist/Calendar.css";
import "../styles/react-date-picker.css";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#455660",
        },
        secondary: {
            main: "#1f9eef",
        },
    },
});

export default function IVRTable(event) {
    const cookies = useMemo(() => new Cookies(), []);
    const [value, setValue] = useState(0);
    const [callLog, setCallLog] = useState([]);
    const [refreshingResults, setRefreshingResults] = useState(false);
    const [dateRequested, setDateRequested] = useState(new Date());

    //Error Modal
    const [err, setError] = useState(null);

    //File Display
    const [files, setFiles] = useState([]);
    let [ivrType, setIvrType] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [downloadFileUrl, setDownloadFileUrl] = useState("");
    const downloadFileRef = useRef(null);

    //Timestamp Formatter
    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const formattedDate = date.toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        const formattedTime = date.toLocaleTimeString(undefined, {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
        return { formattedDate, formattedTime };
    };

    const updateCallLog = useCallback(
        async (controller) => {
            try {
                setRefreshingResults(() => true);

                const requestParams = {
                    method: "GET",
                    redirect: "follow",
                    headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
                };
                if (controller) requestParams.signal = controller.signal;

                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/ivr-transcriptions?processor=${event.ivrType}&ivrType=aaa`, requestParams);

                const data = await response.json();
                setCallLog(() => (response.ok ? data : []));
            } catch (err) {
                setCallLog(() => []);
                if (err.name === "AbortError") return;
                console.log(err);
                alert(err.name === "PTPException" ? err.message : "Failed to retrieve results");
            } finally {
                setRefreshingResults(() => false);
            }
        },
        [cookies]
    );

    useEffect(() => {
        const controller = new AbortController();
        updateCallLog(controller);
        return () => controller.abort();
    }, [updateCallLog]);

    useEffect(() => {
        const controller = new AbortController();
        const interval = setInterval(async () => {
            await updateCallLog(controller);
        }, 60000);

        return () => {
            clearInterval(interval);
            controller.abort();
        };
    }, [updateCallLog]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleGenerateButton = async (event) => {
        try {
            setIvrType(event.ivrType);
            const ivrTypeResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/ivr-get-results`, {
                method: "POST",
                body: JSON.stringify({
                    ivrType: ivrType,
                    dateRequested: dateRequested,
                }),
                headers: {
                    "Content-Type": "application/json",
                    // "Access-Control-Allow-Origin": "*",
                    // Accept: "application/json",
                    Authorization: `Bearer ${cookies.get("_auth")}`,
                },
            });
            console.log(ivrTypeResponse);
            console.log(ivrType);
        } catch (err) {
            console.log(err);
        }
    };

    const listResults = async () => {
        try {
            const ivrListResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/ivr-list-results-files`, {
                method: "GET",
                //body: JSON.stringify(data),
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
            });

            const fileData = await ivrListResponse.json();
            setFiles(fileData.files.Contents);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        listResults();
    }, [cookies]);

    const handleCloseError = () => {
        setError(null);
    };

    const handleDownloads = async (file) => {
        try {
            console.log("Downloads");

            const key = file.Key;

            const urlResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/ivr-result-files-presigned-url`, {
                method: "POST",
                body: JSON.stringify({
                    Key: key,
                }),
                headers: {
                    Authorization: `Bearer ${cookies.get("_auth")}`,
                },
            });
            if (!urlResponse.ok) throw new Error("Download Error.");

            console.log(urlResponse);

            const urlData = await urlResponse.json();
            const downloadUrl = urlData.url;
            setDownloadFileUrl(downloadUrl);
            console.log(downloadUrl);
        } catch (err) {
            console.log(err);
            alert("File unable to download.");
        }
    };

    useEffect(() => {
        if (!downloadFileUrl) {
            const controller = new AbortController();
            listResults(controller);
            return () => controller.abort();
        }

        downloadFileRef.current?.click();
    }, [downloadFileUrl]);

    return (
        <ThemeProvider theme={darkTheme}>
            <AppBar position="static">
                <Box sx={{ bgcolor: "background.paper", borderBottom: 4, borderColor: "divider" }}>
                    {value === 0 && (
                        <div style={{ display: "flex", justifyContent: "flex-end", padding: 8 }}>
                            <IconButton size="large" style={{ color: "white", fontWeight: "bold" }} variant="contained" color="secondary" onClick={listResults}>
                                <RefreshIcon fontSize="large" />
                            </IconButton>
                        </div>
                    )}
                    <Tabs value={value} onChange={handleChange} textColor="inherit" indicatorColor="secondary">
                        <Tab label={<Typography variant="h5"> IVR Results</Typography>}></Tab>
                        <Tab label={<Typography variant="h5"> Transcriptions</Typography>}></Tab>
                    </Tabs>
                    <div style={{ display: value === 0 ? "block" : "none" }}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h5">Date</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h5">TimeStamp</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h5">File</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h5">Status</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {files?.map((file, index) => {
                                        const { formattedDate, formattedTime } = formatDate(file.LastModified);

                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Typography variant="h6">{formattedDate}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="h6">{formattedTime}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="h6">{file.Key}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <a href={downloadFileUrl} ref={downloadFileRef} />
                                                    <Button
                                                        variant="contained"
                                                        style={{ backgroundColor: "#1f9eef", color: "white" }}
                                                        onClick={() => handleDownloads(file)}
                                                    >
                                                        Download
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{ p: 2 }} textAlign="center">
                            <Box sx={{ paddingBottom: 2 }}>
                                <Typography variant="h6" style={{ color: "white", marginRight: 2 }}>
                                    Date
                                </Typography>
                                <DatePicker
                                    sx={{ paddingRight: 10 }}
                                    onChange={(newDate) => setDateRequested(() => newDate)}
                                    value={dateRequested}
                                    minDate={new Date("1900-01-01")}
                                />
                            </Box>
                            <Button
                                // style={{ color: "white", fontWeight: "bold" }}
                                style={{ backgroundColor: isDisabled ? "#d9544d" : "#1f9eef", color: "white" }}
                                //disabled={isDisabled}
                                variant="contained"
                                color="secondary"
                                event={event}
                                onClick={() => handleGenerateButton(event)}
                            >
                                <Typography variant="h6">Generate IVR Results </Typography>
                            </Button>
                        </Box>
                    </div>
                    <div style={{ display: value === 1 ? "block" : "none" }} className={resultLogStyles.resultLog}>
                        {callLog
                            .sort((call1, call2) => {
                                return new Date(call2.timestamp) - new Date(call1.timestamp);
                            })
                            .map((call) => (
                                <div className={resultLogStyles.resultLogEntry} key={call.call_id}>
                                    <p className={resultLogStyles.resultLogText}>Twilio Call SID: {call.call_id}</p>
                                    <p className={resultLogStyles.resultLogText}>NPI: {call.npi}</p>
                                    {/* <p className={resultLogStyles.resultLogText}>Date: {call.date_filled}</p> */}
                                    {event.ivrType === "esi" ? <p className={resultLogStyles.resultLogText}>Date: {call.date_filled}</p> : null}
                                    <p className={resultLogStyles.resultLogText}>Rx #: {call.rx_number}</p>
                                    <p className={resultLogStyles.resultLogText}>Transcription: {call.transcription}</p>
                                    <p className={resultLogStyles.resultLogText}>
                                        Claim Details: {call.claimdetails ? call.claimdetails : "No Claim Details Available"}
                                    </p>
                                    {/* {call.claimdetails && event.ivrType === "esi" ? (
                                        <p className={resultLogStyles.resultLogText}>
                                            Claim Details: {call.claimdetails ? call.claimdetails : "No Claim Details Available"}
                                        </p>
                                    ) : null} */}
                                </div>
                            ))}
                    </div>
                </Box>
            </AppBar>

            {/* Error Transcription Modal */}
            <Dialog open={err !== null} onClose={handleCloseError} err={err}>
                <DialogTitle>
                    <Typography variant="h5">Transcription Error</Typography>
                    <IconButton onClick={handleCloseError} sx={{ position: "absolute", right: 8, top: 8 }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ width: "500px" }}>
                    <DialogContentText>
                        <Typography variant="h6">Your transcriptions are not finished. Please try again later.</Typography>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
}

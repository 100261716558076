import * as React from "react";
import Select from "@mui/material/Select";
import { FormControl, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import simpleFormStyles from "../styles/simple-form.module.css";

export default function QueueButton({ cookies, queueNumber, handleQueueNumber }) {
    return (
        <FormControl variant="standard" sx={{ minWidth: 250 }}>
            <div className={simpleFormStyles.simpleFormInputContainer}>
                <label className={simpleFormStyles.simpleFormLabel}>Queue:</label>
                <Select
                    sx={{
                        position: "relative",
                        bottom: "7.5px",
                        backgroundColor: "white",
                        height: "30px",
                        width: "100px",
                        paddingLeft: "15px",
                    }}
                    labelId="queue-selector-label"
                    id="queue-selector"
                    value={queueNumber}
                    label="QueueNumber"
                    onChange={handleQueueNumber}
                >
                    <MenuItem value="" disabled>
                        Select Queue
                    </MenuItem>
                    <MenuItem value={"queue1"}>
                        <Typography variant="h5">Queue 1</Typography>
                    </MenuItem>
                    <MenuItem value={"queue2"}>
                        <Typography variant="h5">Queue 2</Typography>
                    </MenuItem>
                </Select>
            </div>
        </FormControl>
    );
}

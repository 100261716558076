import Cookies from "universal-cookie";
import DatePicker from "react-date-picker";
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { LoadingModal } from "./LoadingModal.jsx";
import { ConfirmationModal } from "./ConfirmationModal.jsx";
import { PTPException } from "../utils/models/ptpException.js";
import { isValidAaaHitlistRequest } from "../utils/scripts/inputValidations.js";
import simpleFormStyles from "../styles/simple-form.module.css";
import "react-calendar/dist/Calendar.css";
import "../styles/react-date-picker.css";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import ContentCutIcon from "@mui/icons-material/ContentCut";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#455660",
        },
        secondary: {
            main: "#1f9eef",
        },
    },
});

const SelectInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(1),
    },
    "& .MuiInputBase-input": {
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #black",
        paddingTop: theme.spacing(2),
        fontSize: 16,
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
            color: "white",
        },
    },
}));

// const StyledFormControl = styled(FormControl)(({ theme }) => ({
//     "& .MuiFormControlLabel-root": {
//         position: "relative",
//         backgroundColor: theme.palette.background.paper,
//         border: "1px solid #black",
//         marginLeft: theme.spacing(4),
//         marginRight: theme.spacing(4),
//         marginBottom: theme.spacing(1),
//         fontSize: 16,
//         borderRadius: "4px",

//         transition: "background 0.37s",
//         "&:hover": {
//             backgroundColor: theme.palette.action.selected,
//             transition: "background 0.29s",
//         },
//     },

//     "& .MuiFormLabel-root": {
//         color: "black",
//         fontWeight: "bold",
//         marginLeft: "2rem",
//         fontSize: 12,
//         zIndex: 0,
//         textAlign: "left",
//         transition: "color 0.37s",
//         "&.Mui-focused": {
//             color: theme.palette.secondary.main,
//             transition: "color 0.29s",
//         },
//     },

//     "& .MuiCheckbox-root": {
//         color: theme.palette.secondary.main,
//         "&.Mui-checked": {
//             color: theme.palette.secondary.main,
//         },
//     },
// }));

export default function AAATrimRefresh() {
    const cookies = useMemo(() => new Cookies(), []);
    const aaaProcessMode = useSelector((state) => state.aaaProcess.processMode);
    const programName = useSelector((state) => state.program.name);

    const [dateRangeStart, setDateRangeStart] = useState(((dt) => new Date(dt.setDate(dt.getDate() - 1)))(new Date()));
    const [dateRangeEnd, setDateRangeEnd] = useState(((dt) => new Date(dt.setDate(dt.getDate() - 1)))(new Date()));
    const [reportType, setReportType] = useState("All");

    const [executingProcess, setExecutingProcess] = useState(false);
    const [confirmationModalActive, setConfirmationModalActive] = useState(false);

    const runAaaDispatchProcess = async (e) => {
        try {
            setExecutingProcess(() => true);
            console.log("runAAADispatch.");
            const body = {
                program: programName,
                params: {
                    dateRangeStart: dateRangeStart,
                    dateRangeEnd: dateRangeEnd,
                    reportType: reportType,
                },
                aaaProcess: aaaProcessMode,
            };

            // should throw here
            const aaaDispatchProcessErrorCheck = isValidAaaHitlistRequest(body);
            if (aaaDispatchProcessErrorCheck) throw new PTPException(aaaDispatchProcessErrorCheck);

            const aaaDailyResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-process-dispatcher`, {
                method: "POST",
                body: JSON.stringify(body),
                headers: { Authorization: `Bearer ${cookies.get("_auth")}` },
            });

            if (!aaaDailyResponse.ok) throw new PTPException("Failed to run AAA daily");
            // const aaaDailyData = await aaaDailyResponse.json();

            // console.log(aaaDailyResponse);
            displayConfirmationModal();
        } catch (err) {
            console.log(err);
            alert(err.name === "PTPException" ? err.message : "Failed to run AAA daily. Catch.");
        } finally {
            setExecutingProcess(() => false);
        }
    };

    const displayConfirmationModal = () => {
        setConfirmationModalActive(() => true);

        setTimeout(() => {
            setConfirmationModalActive(() => false);
        }, 2500);
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <div className="centered-container">
                {executingProcess && <LoadingModal text="Dispatching process..." />}
                {confirmationModalActive && <ConfirmationModal text="Process successfully dispatched"></ConfirmationModal>}
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={reportType === "All" || reportType === "Target" ? 5 : 6}>
                        <form className={simpleFormStyles.simpleForm}>
                            <div className={simpleFormStyles.simpleFormInputContainer}>
                                <label className={simpleFormStyles.simpleFormLabel}>Date Processed:</label>
                                <DatePicker onChange={(newDate) => setDateRangeStart(() => newDate)} value={dateRangeStart} minDate={new Date("2000-01-01")} />
                                <DatePicker onChange={(newDateB) => setDateRangeEnd(() => newDateB)} value={dateRangeEnd} minDate={new Date("2000-01-01")} />
                            </div>

                            <Box textAlign="center" alignItems="top" sx={{ display: "flex", flexDirection: "row", height: "56px" }}>
                                <FormControl
                                    sx={{
                                        margin: "auto",
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        width: "100%",
                                        paddingLeft: "2rem",
                                    }}
                                >
                                    <InputLabel sx={{ color: "black", fontWeight: "bold", fontSize: 16, zIndex: 0 }}>Report Type</InputLabel>
                                    <Select
                                        id="reportTypes"
                                        label="reportTypes"
                                        onChange={(e) => setReportType(e.target.value)}
                                        value={reportType}
                                        input={<SelectInput />}
                                    >
                                        <MenuItem value="All" sx={{ fontSize: 12 }}>
                                            All
                                        </MenuItem>
                                        <MenuItem value="Target" sx={{ fontSize: 12 }}>
                                            Target
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <div className={simpleFormStyles.simpleFormSubmitContainer}>
                                    <Button
                                        sx={{ width: "90%", margin: "auto", paddingBlock: "1rem" }}
                                        color="secondary"
                                        variant="contained"
                                        size="large"
                                        startIcon={<ContentCutIcon />}
                                        onClick={runAaaDispatchProcess}
                                    >
                                        Trim
                                    </Button>
                                </div>
                            </Box>
                            {/* <div className={simpleFormStyles.simpleFormSubmitContainer}>
                            <button type="button" className="run">
                                Trim
                            </button>
                        </div> */}
                        </form>
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    );
}

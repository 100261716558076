import { createSlice } from "@reduxjs/toolkit";

export const aaaProcessSlice = createSlice({
	name: "aaaProcess",
	initialState: {
		processMode: "",
	},

	reducers: {
		changeAaaProcess: (state, action) => {
			state.processMode = action.payload;
		},
	},
});

export const { changeAaaProcess } = aaaProcessSlice.actions;
export default aaaProcessSlice.reducer;

import { useState } from "react";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import styles from "../styles/login.module.css";

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loggingIn, setLoggingIn] = useState(false);
    const signIn = useSignIn();
    const navigate = useNavigate();

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoggingIn(() => true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login`, {
                method: "POST",
                body: JSON.stringify({ username: username, password: password }),
                headers: { Accept: "application/json" },
            });

            const data = await response.json();
            if (!data.token) return false;

            signIn({
                token: data.token,
                expiresIn: 60 * 12,
                tokenType: "Bearer",
                authState: { username: username },
            });

            navigate("/");
        } catch (err) {
            console.log(err);
        } finally {
            setLoggingIn(() => false);
        }
    };

    return (
        <div className={styles.loginWrapper}>
            <form className={styles.loginForm} onSubmit={onSubmit}>
                <div className={styles.loginInputContainer}>
                    <label className={styles.loginLabel}>Username:</label>
                    <input required className={styles.loginInput} onChange={(e) => setUsername(e.target.value)}></input>
                </div>
                <div className={styles.loginInputContainer}>
                    <label className={styles.loginLabel}>Password:</label>
                    <input required type="password" className={styles.loginInput} onChange={(e) => setPassword(e.target.value)}></input>
                </div>
                <div className={styles.loginInputContainer}>
                    <input type="submit" value="Log in" className={styles.loginSubmit} disabled={loggingIn}></input>
                </div>
            </form>
        </div>
    );
}

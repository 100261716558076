import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";

// Pages
import Home from "./pages/Home.jsx";
import Login from "./pages/Login.jsx";
import AAA from "./pages/AAA.jsx";
import IVRs from "./pages/IVRs.jsx";
import CaremarkBpsIvrFullTranscriptions from "./pages/CaremarkBpsIvrFullTranscriptions.jsx";

import { Header } from "./components/Header.jsx";

export default function App() {
	const PrivateRoute = ({ Component }) => {
		const isAuthenticated = useIsAuthenticated();
		const auth = isAuthenticated();
		return auth ? <Component /> : <Navigate to="/login" />;
	};

	return (
		<BrowserRouter>
			<Header />
			<Routes>
				<Route path="/login" element={<Login />}></Route>
				<Route path="/" element={<PrivateRoute Component={Home} />}></Route>
				<Route path="/index.html" element={<PrivateRoute Component={Home} />}></Route>
				<Route path="/triple-a" element={<PrivateRoute Component={AAA} />}></Route>
				<Route path="/ivrs" element={<PrivateRoute Component={IVRs} />}></Route>
				<Route path="/caremark-full-transcriptions" element={<PrivateRoute Component={CaremarkBpsIvrFullTranscriptions} />}></Route>
			</Routes>
		</BrowserRouter>
	);
}

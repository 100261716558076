import DatePicker from "react-date-picker";
import { useState } from "react";
import { LoadingModal } from "./LoadingModal.jsx";
import { ConfirmationModal } from "./ConfirmationModal.jsx";
import { isValidEsiIvrForm } from "../utils/scripts/inputValidations.js";
import { PTPException } from "../utils/models/ptpException.js";
import simpleFormStyles from "../styles/simple-form.module.css";
import "react-calendar/dist/Calendar.css";
import "../styles/react-date-picker.css";

export default function EsiAaaIvrIndividual({ cookies, updateCallLog }) {
  const [npi, setNPI] = useState("");
  const [rxNumber, setRxNumber] = useState("");
  const [dateFilled, setDateFilled] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [confirmationModalActive, setConfirmationModalActive] = useState(false);

  const makeEsiCall = async () => {
    try {
      setLoading(true);
      const body = { npi: npi, rxNumber: rxNumber, dateFilled: dateFilled };

      const formCheckError = isValidEsiIvrForm(body);
      if (formCheckError) throw new PTPException(formCheckError);

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/esi-aaa-ivr`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
      });

      if (!response.ok) {
        console.log(`Failure with call ${JSON.stringify(body)}`);
        throw new PTPException("Call could not be processed at this time");
      }

      await updateCallLog();

      displayConfirmationModal();
    } catch (err) {
      console.log(err);
      alert(err.name === "PTPException" ? err.message : "Call could not be processed at this time");
    } finally {
      setLoading(false);
    }
  };

  const displayConfirmationModal = () => {
    setConfirmationModalActive(() => true);

    setTimeout(() => {
      setConfirmationModalActive(() => false);
    }, 2500);
  };

  return (
    <div className="centered-container">
      {loading === true && <LoadingModal text="Processing call..." />}
      {confirmationModalActive && <ConfirmationModal text="Call successfully processed"></ConfirmationModal>}
      <form className={simpleFormStyles.simpleForm}>
        <div className={simpleFormStyles.simpleFormInputContainer}>
          <label className={simpleFormStyles.simpleFormLabel}>NPI:</label>
          <input
            type="text"
            className={simpleFormStyles.simpleFormInput}
            maxLength="10"
            onChange={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]+/, "");
              setNPI(e.target.value);
            }}
          ></input>
        </div>
        <div className={simpleFormStyles.simpleFormInputContainer}>
          <label className={simpleFormStyles.simpleFormLabel}>Rx #:</label>
          <input
            type="text"
            className={simpleFormStyles.simpleFormInput}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]+/, "");
              setRxNumber(e.target.value);
            }}
          ></input>
        </div>
        <div className={simpleFormStyles.simpleFormInputContainer}>
          <label className={simpleFormStyles.simpleFormLabel}>Date Filled:</label>
          <DatePicker onChange={(newDate) => setDateFilled(() => newDate)} value={dateFilled} minDate={new Date("1900-01-01")} />
        </div>

        <div className={simpleFormStyles.simpleFormSubmitContainer}>
          <button type="button" className="run" onClick={makeEsiCall}>
            Make Call
          </button>
        </div>
      </form>
    </div>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import programReducer from "./program.js";
import aaaProcessReducer from "./aaaProcess.js";
import ivrProcessorReducer from "./ivrProcessor.js";
import batchReducer from "./batch.js";

export default configureStore({
  reducer: {
    program: programReducer,
    aaaProcess: aaaProcessReducer,
    ivrProcessor: ivrProcessorReducer,
    batch: batchReducer,
  },
});

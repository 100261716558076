import { createSlice } from "@reduxjs/toolkit";

export const batchSlice = createSlice({
  name: "batch",
  initialState: {
    name: false,
  },

  reducers: {
    changeBatch: (state) => {
      state.name = !state.name;
    },
  },
});

export const { changeBatch } = batchSlice.actions;
export default batchSlice.reducer;
